import React from 'react';
import { Helmet } from 'react-helmet';
import Button from '../components/Button';
import Card from '../components/Card';
import TestimonialCard from '../components/TestimonialCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
// import SplitSection from '../components/SplitSection';
import testimonialsData from '../data/testimonials';
// import SvgCharts from '../svg/SvgCharts';
import DirectionsLogo from '../svg/directions.jpeg';
import CocktailIcon from '../svg/cocktail.png';
import CocktailsIcon from '../svg/cocktails.png';
import CoffeeIcon from '../svg/coffee.png';
import BreakfastIcon from '../svg/breakfast.png';
import CheeseIcon from '../svg/cheese.png';
import SpanishIcon from '../svg/spanish.png';
import UkIcon from '../svg/uk.png';
import TicTocIcon from '../svg/tictoc.jpeg';
import AgoraIcon from '../svg/logo_agora.jpeg';
import WhatsappIcon from '../svg/whatsapp.png';
import GmailIcon from '../svg/gmail.png';
import InstagramIcon from '../svg/instagram.png';
import VolantinoTicToc from '../data/volantino-tic-toc.pdf';

const Course = ({ img, title, description }) => (
  <div className="flex-1 px-3">
    <div className={`rounded-lg border border-solid border-gray-200 mb-8 p-12`} style={{
      boxShadow: '0 10px 28px rgba(0,0,0,.08)'
    }} >
      <div className="flex flex-col items-center">
        <img className="h-12 mb-6" src={img} />
        <p className="font-semibold text-xl">{title}</p>
        <p className="mt-4">{description}</p>
      </div>
    </div>
  </div>
);

const Initiative = ({ img, title, description }) => (
  <div className="flex-1 px-4">
    <Card className="mb-8 flex flex-col items-center">
      <img src={img} className="h-12 mb-4" />
      <p className="font-semibold text-xl">{title}</p>
      <p className="my-4 h-12">{description}</p>
    </Card>
  </div>
);

const Index = () => {
  const downloadBrochure = () => {
    const link = document.createElement('a');
    link.href = VolantinoTicToc;
    link.download = 'volantino-tic-toc.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="Impara le lingue attraverso l'arte, la musica, le emozioni e i colori!" />
        <meta name="keywords" content="inglese,corsi,lingua,emozioni,francese,spagnolo,musica,lingue,reggio emilia,corsi di inglese,reggio nell'emilia,reggio" />
        <meta property="og:title" content="L'emozione parla in lingua" />
        <meta property="og:description" content="Impara le lingue attraverso l'arte, la musica, le emozioni e i colori!" />
        <meta property="og:url" content="https://www.lemozioneparlainlingua.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.lemozioneparlainlingua.it/directions.jpeg" />
        <title>L'emozione parla in lingua</title>
        <link rel="canonical" href="https://lemozioneparlainlingua.it/" />
      </Helmet>
      <Layout>
        <section className="pt-20 md:pt-40">
          <div className="container mx-auto px-8 lg:flex">
            <div className="text-center lg:text-left lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                Benvenuto su l'Emozione parla in lingua
              </h1>
              <p className="text-xl lg:text-2xl mt-6 font-light">
                Impara le lingue attraverso l'arte, la musica, le emozioni e i colori!
                Percorsi linguistici online e offline inerenti all’apprendimento linguistico attraverso esperienze personalizzate.
              </p>
            </div>
            <div className="lg:w-1/2 flex lg:flex-col mt-4 lg:mt-0 items-center justify-center">
              <img className="shadow-2xl rounded-md" src={DirectionsLogo} style={{height: '400px'}} />
            </div>
          </div>
        </section>
        <section id="corsi" className="py-20 lg:pb-40 lg:pt-48">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">Corsi</h2>
            <LabelText className="mb-8 text-gray-600 text-center">Corsi di lingua</LabelText>
            <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
              <Course img={UkIcon} title="English" description="Welcome aboard!" />
              <Course img={CheeseIcon} title="Français" description="Bienvenue à bord!" />
              <Course img={SpanishIcon} title="Español" description="¡Bienvenido a bordo!" />
            </div>
          </div>
        </section>
        <section id="scuola_infanzia" className="py-20">
          <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Scuola dell'infanzia e primaria</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Bimbi della scuola dell’infanzia e primaria, ai quali si propongono esperienze di lingua straniera in musica (chitarra, canto, ballo)
              </p>
            </div>
          </div>
        </section>
        {/*<SplitSection
          id="scuola_infanzia"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">Scuola dell'infanzia e primaria</h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Bimbi della scuola dell’infanzia e primaria, ai quali si propone esperienze di lingua straniera in musica (chitarra, canto, ballo)
              </p>
            </div>
          }
          // secondarySlot={<SvgCharts />}
        />*/}
        <section id="ragazzi_universitari" className="py-20">
          <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Ragazzi e universitari
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Ragazzi della scuola media inferiore e superiore, universitari in preparazione ad esami o per puro approfondimento linguistico
              </p>
            </div>
          </div>
        </section>
        {/*<SplitSection
          id="ragazzi_universitari"
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Ragazzi e universitari
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Ragazzi della scuola media inferiore e superiore, universitari in preparazione ad esami o per puro approfondimento linguistico
              </p>
            </div>
          }
          // secondarySlot={<SvgCharts />}
        />*/}
        <section id="lavoratori" className="py-20">
          <div className="container mx-auto px-16 items-center flex flex-col lg:flex-row">
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Lavoratori
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Lavoratori di tutte le età ai quali si suggeriscono corsi personalizzati: conversazione generale, linguaggio quotidiano, settori lavorativi o di interesse, esperienze concrete sul posto (bar, ristorante, cinema, fare la spesa e via dicendo)
              </p>
            </div>
          </div>
        </section>
        {/*<SplitSection
          id="lavoratori"
          primarySlot={
            <div className="lg:pr-32 xl:pr-48">
              <h3 className="text-3xl font-semibold leading-tight">
                Lavoratori
              </h3>
              <p className="mt-8 text-xl font-light leading-relaxed">
                Lavoratori di tutte le età ai quali si suggerisce corsi personalizzati: conversazione generale, linguaggio quotidiano, settori lavorativi o di interesse, esperienze concrete sul posto (bar, ristorante, cinema, fare la spesa e via dicendo)
              </p>
            </div>
          }
          // secondarySlot={<SvgCharts />}
        />*/}
        <section id="iniziative" className="py-20 lg:pb-40 lg:pt-48">
          <div className="container mx-auto text-center">
            <h2 className="text-3xl lg:text-5xl font-semibold">Iniziative</h2>
            <LabelText className="mb-8 text-gray-600 text-center">Eventi pubblici e gratuiti</LabelText>
            <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
              <Initiative img={BreakfastIcon} title="Coffee in a cup of English" description="One hour of English conversation while having breakfast" />
              <Initiative img={CocktailIcon} title="Cocktail in a glass of English" description="One hour of English conversation while having a drink" />
              <Initiative img={CoffeeIcon} title="Café dans une tasse de français" description="Une heure de conversation française pendant le petit déjeuner" />
              <Initiative img={CocktailsIcon} title="Cocktail dans un verre de français" description="Une heure de conversation française pendant l'apéritif" />
            </div>
          </div>
        </section>
        <section id="partnership" className="py-20 lg:py-40">
          <div className="container mx-auto">
            <h2 className="text-3xl lg:text-5xl font-semibold text-center">Collaborazioni</h2>
            <LabelText className="mb-8 text-gray-600 text-center">Collaborazioni con altre attività</LabelText>
            <div className="flex flex-col justify-between lg:flex-row md:-mx-3 mt-12">
              <Card className="mx-5 flex-1">
                <div className="flex flex-col sm:flex-row">
                  <div className="mb-6 sm:mb-0 sm:mr-6 flex justify-center">
                    <img
                      className="h-56"
                      src={TicTocIcon}
                    />
                  </div>
                  <div className="flex-1 text-center sm:text-left">
                    <p className="text-gray-600">TicToc - Il tempo vola via</p>
                    <p className="text-xl font-semibold">Inglese in musica per bambini</p>
                    <p className="mt-6">Vi aspettiamo tutti i Martedì dalle 16:30 alle 17:15! 15€ a bambino per lezione!</p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <p className="mt-8 md:mt-12">
                    <Button size="lg" onClick={downloadBrochure}>Scarica la brochure</Button>
                  </p>
                </div>
              </Card>
              <Card className="mx-5 flex-1">
                <div className="flex flex-col sm:flex-row">
                  <div className="mb-6 sm:mb-0 sm:mr-6 flex justify-center">
                    <img
                      className="h-56"
                      src={AgoraIcon}
                    />
                  </div>
                  <div className="flex-1 text-center sm:text-left">
                    <p className="text-gray-600">Agorà - Scuola Materna</p>
                    <p className="text-xl font-semibold">L'emozione in musica parlando in Inglese</p>
                    <p className="mt-6">
                      Durante le lezioni vengono proposte per lo più canzoni suonate con la chitarra o ascoltate tramite supporto audio. All’ascolto seguono attività per imparare e approfondire i testi delle canzoni: canto in gruppo, balletti, giochi all’interno e all’esterno (a seconda del meteo), disegni e piccole conversazioni tra insegnante e bambini, come anche tra piccoli gruppi.
                    </p>
                  </div>
                </div>
                <div className="flex justify-center">
                  <p className="mt-8 md:mt-12">
                    <a className="p-4 pr-8 pl-8 bg-primary hover:bg-primary-darker hover:text-white rounded text-white" size="lg" href="https://www.agora.re.it/progetti.html" target="_blank">Guarda il progetto</a>
                  </p>
                </div>
              </Card>
            </div>
          </div>
        </section>
        <section id="testimonials" className="py-20 lg:py-40">
          <div className="container mx-auto">
            <h2 className="text-3xl lg:text-5xl font-semibold text-center">Testimonial</h2>
            <LabelText className="mb-8 text-gray-600 text-center">Dicono di noi</LabelText>
            <div className="grid md:grid-cols-2 md:-mx-3 mt-12">
              {testimonialsData.map(testimonial => (
                <div key={testimonial.name} className="p-3">
                  <TestimonialCard testimonial={testimonial} />
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="container mx-auto my-20 py-16 bg-gray-200 rounded-lg text-center">
          <h3 className="text-3xl font-semibold">Ready to learn a new language?</h3>
          <p className="mt-8 text-xl font-light">
          </p>
          <div className="mt-8 flex flex-col sm:flex-row justify-center">
            <div className="flex justify-center items-center sm:mr-4 mb-2 sm:mb-0">
              <img className="h-8 mr-2" src={WhatsappIcon} /> <a href="https://wa.me/3402226564">Contattaci su Whatsapp</a>
            </div>
            <div className="flex justify-center items-center sm:mr-4 mb-2 sm:mb-0">
              <img className="h-8 mr-2" src={GmailIcon} /> <a href="mailto:kya88m@gmail.com">Contattaci via Email</a>
            </div>
            <div className="flex justify-center items-center">
              <img className="h-8 mr-2" src={InstagramIcon} /> <a href="https://instagram.com/emozione_parla_in_lingua" target="_blank">Seguici su Instagram</a>
            </div>
          </div>
        </section>
        <section className="container mx-auto my-4 py-2 bg-gray-200 rounded-lg text-center text-xs">
          Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </section>
      </Layout>
    </>
  )
};

export default Index;
