import Elisabetta from '../svg/betty.png';
import Daniela from '../svg/daniela.png';
import Andrea from '../svg/andrea.png';
import Silvia from '../svg/silvia.png';
import BarbaraGraziella from '../svg/barbara-graziella.png';
import Gemma from '../svg/gemma.png';
import Giada from '../svg/giada.png';
import ReadingWoman from '../svg/reading-woman.png';

export default [
  {
    title: 'Metodo di studio originale e divertente',
    content:
      'Stanca di tanti corsi anonimi, a Marzo di 2 anni fa ho iniziato a frequentare un corso di inglese "one to one" accompagnata da Kya. Volevo imparare a parlare una lingua che, per me e per la mia generazione, risulta essere sempre molto ostica. Da subito c\'è stata una grande intensa e mi sono sentita sostenuta e aiutata nell\'apprendimento. Kya ha la capacità di incoraggiarti e di personalizzare lo studio dell\'Inglese, in base ai tuoi interessi. Solo così uno può appassionarsi a una lingua: partendo dalle proprie passioni! Applica un metodo di studio originale e divertente. Sono molto contenta di averla incontrata sul mio cammino, non la mollerò tanto facilmente! Never give up!',
    name: 'Elisabetta',
    jobTitle: 'Felicemente Infermiera',
    image: Elisabetta
  },
  {
    title: 'Insegna sempre con il Sorriso sulle labbra',
    content:
      'Se da ragazzina avessi avuto un’insegnante come Chiara, sicuramente mi avrebbe fatto amare la lingua Inglese.. ma tutto arriva al momento giusto! Che dire di Chiara? Appassionata e appassionante! Insegna sempre con il Sorriso sulle labbra; ti corregge senza farti mai sentire in imbarazzo. Sempre aggiornata e motivante! Grazie Chiara per la pazienza e con me ce ne vuole tanta!',
    name: 'Daniela',
    jobTitle: 'Addetta a Controllo e Qualità',
    image: Daniela
  },
  {
    title: 'Perdere la paura di parlare Inglese',
    content:
      'Grazie alle \'conversation flow\' di Chiara ho perso la paura di parlare inglese. Ci sono sempre argomenti interessanti e si parla un inglese moderno, quello che si usa quando si viaggia o si vuole fare conversazione. In poche lezioni, si vede già una grandissima differenza.',
    name: 'Andrea',
    jobTitle: 'Tecnico informatico',
    image: Andrea
  },
  {
    title: 'Crea dipendenza!',
    content:
      `Sono Silvia e lavoro in un ufficio tecnico di un'azienda metalmeccanica: l'inglese è la mia lingua tecnica. Visto che non l'ho studiato nelle scuole dell'obbligo, ho frequentato tanti tipi di corsi di inglese con altrettante insegnati, dai corsi serali ai corsi individuali ai corsi privati face to face. Chiara, a mio avviso, sa scoccare la freccia INGLESE da molte angolazioni, andando oltre all'ovvia grammatica e alla conversazione scolastica; ti sprona a utilizzare l'Iinglese in svariate situazioni, che sia un gioco, un incontro al bar, una creazione scritta o verbale, facendo così sedimentare stabilmente le conoscenze per noi studenti poco “distratti” del mondo del lavoro. Quello che ho sempre apprezzato di Chiara è che ti spiega un concetto, una regola fondamentale e ci resta finché questa regola non è ben assimilata. Per me questo è stato un metodo vincente, facendomi fare un salto di qualità, in quanto, con le altre insegnanti, mi riempivo la testa di regole e sotto-regole ed eccezioni talmente tanto che poi entravo in confusione e non riuscivo a metterle in pratica. Posto come base ben consolidata, poi sono riuscita a ricordare meglio le varie eccezioni e finalmente ad esprimerle. Tutto questo è supportato dalla sua infinita pazienza e attitudine solare che, tutto sommato, rendono piacevoli le ore passate con lei talmente tanto che ti stimolano a ritagliare momenti personali per lo studio o pratica individuale. Ma attenzione… che crea dipendenza!`,
    name: 'Silvia',
    jobTitle: 'Impiegata ufficio tecnico',
    image: Silvia
  },
  {
    title: `L'emozione dei bambini`,
    content:
      'Chiara si è proposta di tenere alcune lezioni di inglese per i bambini della nostra scuola (3-5 anni). Abbiamo accettato con entusiasmo! Infatti, come insegnanti, pensiamo che proporre un approccio alla lingua inglese fin dalla scuola dell\'infanzia sia molto importante, in quanto favorisce lo sviluppo generale dei bambini, il loro potenziale affettivo, sociale e cognitivo, abituandoli al pensiero flessibile. Quando Chiara si è presentata ai bimbi con i suoi colori, i suoi modi sensibili e il corso è cominciato, abbiamo potuto respirare quante attese erano nate nella sezione. In tutto il percorso abbiamo notato ed apprezzato come Chiara abbia sempre messo in primo piano l\'aspetto emozionale dei bambini, non solo riconoscendolo e rispettandolo ma anche rafforzandolo. Molto importante è stato l\'incoraggiamento per ogni loro progresso sia a livello di acquisizione della lingua che a livello emotivo e soggettivo. Nella vita quotidiana della scuola abbiamo notato come i bambini ripetessero parole in inglese, ridendo e scherzando, in un confronto fra loro. E non per ultimo, abbiamo imparato a conoscerci meglio, a cogliere sfumature diverse e nuove dell\'altro. E\' stata un\'esperienza molto bella che speriamo di ripetere presto!',
    name: 'Barbara & Graziella',
    jobTitle: 'Insegnanti Scuola Primaria',
    image: BarbaraGraziella
  },
  {
    title: 'Apprendere con naturalezza',
    content:
      `Un corso di inglese privato a quarant'anni può nascere da diverse esigenze, lavorative o personali. Io volevo superare la mia ignoranza e il mio imbarazzo quando mi trovavo a doverlo parlare. È stato importante quindi trovare un'insegnante accogliente, con la passione per la materia e desiderosa di fare scoprire il bello di una lingua diversa dalla nostra, senza rigidità. Ho appreso con naturalezza. 
Inoltre, lavorando molte ore, è stato importante incontrare un'insegnante che desiderasse accompagnarmi nell'apprendimento e non imporlo, sapendo capire e rispettare stanchezze o mancanze.`,
    name: 'Gemma',
    jobTitle: 'Operatrice Culturale',
    image: Gemma
  },
  {
    title: 'Pratico e divertente!',
    content:
      `È un corso davvero interessante, stimolante e divertente!
Un'ottima combinazione fra teoria e pratica.
Per tutta la lezione si parla inglese e ciò mi ha permesso di allenarmi e di acquisire sempre più familiarità con la lingua.
Chiara è molto disponibile, attenta, super simpatica e davvero preparata.
Ho imparato molto!!
Consigliatissimo per chi vuole imparare una lingua in modo sopratutto pratico e divertente!!`,
    name: 'Giada',
    jobTitle: `Insegnante di scuola dell'infanzia & primaria`,
    image: Giada
  },
  {
    title: 'Confidence through conversation',
    content:
      `Chiara is a colorful teacher: she is always in a good mood, sending good vibes also while teaching. Our classroom started 10 months ago: I had forgotten almost everything and I hadn’t anymore the self confidence to speak in English. My goal was to be able to host a meeting or write emails and gain the confidence to read, listening and speak again. I think I have reached my goal - and this is just another starting point - and the time has pleasently passed. And yes: I wrote this review in English on my own!`,
    name: 'Federica',
    jobTitle: `Project Manager Digital Division`,
    image: ReadingWoman
  },
];
